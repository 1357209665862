<template>
  <a-spin :spinning="spinning">
    <div :class="{ disabled: disabled }">
      <a-form-model layout="inline" :model="adjustForm" :rules="rules" ref="adjustForm" style="margin-top:3px">
        <a-card class="card" title="业务信息" :bordered="false" style="margin-top:3px;line-height: 1;">
          <div slot="extra">
            <a-affix
              :offsetTop="50"
              :style="{ position: 'absolute', top: '15px', left: 0, width: '98%', 'text-align': 'right' }"
            >
              <a-button><router-link :to="{ name: 'YmsAdjust' }">取消</router-link></a-button>
              <a-divider type="vertical" />
              <a-button :disabled="disabled ? true : false" type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
            </a-affix>
          </div>
          <a-row :gutter="24">
            <a-col :span="6">
              <a-form-model-item label="订单编号" prop="serial_num">
                <a-input v-model.trim="adjustForm.serial_num" @blur="handleDetailBySerialNum(adjustForm.serial_num)"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="结算单">
                <a-select
                  placeholder="请选择结算单"
                  style="width: 200px"
                  v-model="adjustForm.balance_id"
                >
                  <a-select-option v-for="op in orderBalancePaymentList" :key="op.id">{{ op.serial_num }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="调整项目（修改费用通过删除费用、添加费用操作）" :selfUpdate="false" prop="adjust_types">
                <a-checkbox-group :options="matchAdjustTypeOps" v-model="adjustForm.adjust_types">
                  <span slot="label" slot-scope="option">{{ option.name }}</span>
                </a-checkbox-group>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="备注">
                <a-input style="width: 600px" v-model.trim="adjustForm.remark"/>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('重新开票')>=0 || adjustForm.adjust_types.indexOf('作废发票')>=0" class="card pc"
          :title="adjustForm.adjust_types.indexOf('作废发票')>=0?'作废发票':'重新开票'" :bordered="false"
          style="margin-top:-3px;line-height: 0.5;">
          <a-row>
            <a-col :span="12">
              <a-form-model-item label="原发票">
                <a-input style="width: 600px" placeholder="对账发票用;分割" v-model="adjustForm.original_invoices"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="发票备注">
                <a-textarea style="width: 600px" placeholder="对账发票用;分割" v-model="adjustForm.invoice_remark"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <edit-table
                :columns="invoiceSubjectColumns"
                :sourceData="invoiceSubjectData"
                :scroll="true"
                :operate="{ add: true, del: true, alldel: false }"
                @getTableDate="getInvoiceSubjectTableDate"></edit-table>
            </a-col>
            <a-col :span="12">
              <a-form-model-item>
                <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('新增费用')>=0" class="card pc" title="新增费用" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-tabs>
            <a-tab-pane tab="费用信息" key="1">
              <edit-table :columns="chargeFeeColumns" :sourceData="chargeFeeData" @getTableDate="getChargeFeeData"></edit-table>
            </a-tab-pane>
          </a-tabs>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('删除费用')>=0" class="card pc" title="删除费用" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-table
            ref="table"
            size="small"
            row-key="id"
            :filterColumn="true"
            :columns="feeColumns"
            :data-source="originalFeeData"
            :row-selection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
            :scroll="{ x: 'max-content' }">
            <template slot="feeTypeSlot" slot-scope="text">
              <a-tag :color="text===1?'blue':'red'">{{text===1?'应收':'应付'}}</a-tag>
            </template>
          </a-table>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('修改客户')>=0" class="card pc" title="修改客户" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原客户名称">
                <a-input style="width: 300px" v-model="adjustForm.original_customer_name" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新客户名称">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择委托单位"
                  style="width: 300px"
                  :value="customerInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentCustomer"
                  @change="changeCustomerInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in agencyOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('修改供应商')>=0" class="card pc" title="修改供应商" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原供应商名称">
                <a-input style="width: 300px" v-model="adjustForm.original_customer_name" disabled/>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新供应商名称">
                <a-select
                  showSearch
                  label-in-value
                  :filter-option="false"
                  allowClear
                  placeholder="请选择供应商"
                  style="width: 300px"
                  :value="supplierInfo"
                  :not-found-content="fetching ? undefined : null"
                  @search="getDepartmentSupplier"
                  @change="changeSupplierInfo"
                >
                  <template v-if="fetching" #notFoundContent>
                    <a-spin size="small" />
                  </template>
                  <a-select-option v-for="op in supplierOps" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
        <a-card v-if="adjustForm.adjust_types.indexOf('修改费用种类')>=0" class="card pc" title="修改费用种类" :bordered="false" style="margin-top:-3px;line-height: 0.5;">
          <a-row :gutter="24">
            <a-col :span="12">
              <a-form-model-item label="原费用种类">
                <a-select v-model="adjustForm.original_fee_class" disabled style="width:300px">
                  <a-select-option v-for="op in ymsFeeClassOps" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item label="新费用种类">
                <a-select v-model="adjustForm.new_fee_class" style="width:300px">
                  <a-select-option v-for="op in matchFeeClassList" :key="op.id">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-card>
      </a-form-model>
    </div>
  </a-spin>
</template>
<script>
import { getCommonOptions, getCustomer, getSupplier } from '@/api/common'
import { getFirstLetter } from '@/utils/util'
import {
  getYmsDetailBySerialNo,
  saveAdjustOrder,
  initYmsAdjust
} from '@/api/yms'
import { EditTable, BoxItemGroup, ItemGroup } from '@/components'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick' // 防多次点击，重复提交
import debounce from 'lodash/debounce'
import { listSecondInvoiceSubject } from '@/api/fms'
export default {
  components: {
    EditTable,
    BoxItemGroup,
    ItemGroup,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    this.getDepartmentSupplier = debounce(this.getDepartmentSupplier, 800)
    return {
      adjustForm: {
        order_id: null,
        order_type: null,
        balance_id: null,
        balance_num: null,
        serial_num: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        original_fee_class: null,
        new_fee_class: null,
        add_fees: [],
        delete_fee_ids: [],
        original_invoices: null
      },
      orderBalancePaymentList: [],
      orderPaymentList: [],
      originalFeeData: [],
      selectedRowKeys: [],
      selectedRows: [],
      // /////////////
      businessExpand: true,
      allocationExpand: true,
      billInfoExpand: true,
      confirmLoading: false,
      spinning: false,
      isEdit: false,
      customerInfo: undefined,
      supplierInfo: undefined,
      fetching: false,
      colSpan: {
        xxl: 6,
        lg: 7,
        md: 8
      },
      visible: false,
      disabled: false,
      box_info: [{ id: null, num: null, box_num: '' }],
      store_type: [], // 型号
      trans_num: [], // 航次和航班号公用
      send_info: [],
      eng_name: [],
      agencyOps: [],
      supplierOps: [],
      agentOps: [], // 订舱代理
      agentAboardOps: [],
      customOps: [],
      adjustTypeOps: [],
      matchAdjustTypeOps: [],
      ymsFeeClassOps: [],
      matchFeeClassList: [],
      payTargets: [],
      bsData: [], // 保税信息
      chargeFeeData: [],
      payFeeData: [],
      feeList: [],
      chargeFeeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_name_id',
          width: 300,
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 6, type: 1 }],
            options: []
          }
        },
        {
          title: '是否为净值',
          dataIndex: 'net_worth',
          extra: {
            type: 'select',
            // defaultValue: 1,
            options: [
              { value: 0, name: '否' },
              { value: 1, name: '是' }
            ]
          }
        },
        {
          title: '净值',
          dataIndex: 'de_tax',
          customRender: (text, record, index) => {
            return (
              <a-input-number
                disabled={this.chargeFeeData[index]['net_worth'] === 0}
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.chargeFeeData[index]['de_tax'] = val
                  const taxRate = this.chargeFeeData[index]['tax_rate']
                  if ((val && val > 0) && taxRate > 0) {
                    this.chargeFeeData[index]['money'] = parseFloat(val + (val * (taxRate / 100))).toFixed(2)
                  }
                }}
              />
            )
          }
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          customRender: (text, record, index) => {
            return (
              <span>
                <a-input-number
                  disabled={this.chargeFeeData[index]['net_worth'] === 0}
                  style="width:85%"
                  value={text}
                  min={0}
                  onChange={val => {
                    // 税率输入整数 / 100、得到实际税率
                    this.chargeFeeData[index]['tax_rate'] = val
                    const deTax = this.chargeFeeData[index]['de_tax']
                    if ((val && val > 0) && deTax > 0) {
                      this.chargeFeeData[index]['money'] = parseFloat(deTax + (deTax * (val / 100))).toFixed(2)
                    }
                  }}
                />
                <span style="margin-left: 5px;">%</span>
              </span>
            )
          }
        },
        {
          title: '含税金额',
          dataIndex: 'money',
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.chargeFeeData[index]['money'] = val
                }}
              />
            )
          }
        }
      ],
      feeColumns: [
        {
          title: '费用类型',
          dataIndex: 'fee_type',
          scopedSlots: { customRender: 'feeTypeSlot' }
        },
        {
          title: '费用名称',
          dataIndex: 'fee_name'
        },
        {
          title: '是否为净值',
          dataIndex: 'net_worth',
          customRender: (text) => {
            return text === 1 ? '是' : '否'
          }
        },
        {
          title: '净值',
          dataIndex: 'de_tax'
        },
        {
          title: '税率%',
          dataIndex: 'tax_rate'
        },
        {
          title: '金额（含税）',
          dataIndex: 'money'
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text] && record.deny_reason) {
              return this.GLOBAL.feeStatusMaps[text].name + `:${record.deny_reason[0].deny_reason}`
            } else if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          },
          extra: {
            defaultValue: 1,
            disabled: record => {
              return record.disabled
            }
          }
        }
      ],
      // 应收金额统计展示
      totalChargeFee: 0,
      // 应付金额统计展示
      totalPayFee: 0,
      // 表单校验
      rules: {
        // 报价编号
        serial_num: [{ required: true, message: '请输入报价编号', trigger: 'blur' }],
        // 业务类型
        business_type: [{ required: true, message: '请选择业务类型', trigger: 'blur' }],
        // 服务类型
        service_types: [{ required: true, message: '请选择服务类型', trigger: 'blur' }],
        balance_id: [{ required: true, message: '请选择结算单', trigger: 'blur' }]
      },
      totalInvoiceAmount: 0.00,
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  var opName = op.subject_name
                  if (op.subject_spec) {
                    opName = opName + '/' + op.subject_spec
                  }
                  opName = opName + '/' + op.tax_rate
                  return <a-select-option key={op.id}>{opName}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                min={0}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        }
      ],
      invoiceSubjectData: [],
      secondInvoiceSubjectList: [],
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }]
    }
  },
  watch: {
    $route: function(newRoute) {
      this.resetAdjustForm()
      // this.disabled = false
      // if (!this.queryParam && newRoute.name === 'SeaOrderCUR') {
      //   // 编辑页路由监控
      //   this.handleFormReset()
      //   this.$route.meta.title = '新增订单'
      //   if (newRoute.params.id === 'create' || newRoute.query.isCopy) {
      //     // 新增或复制
      //     if (newRoute.query.isCopy) {
      //       this.getDetailData(newRoute.params.id, true)
      //     } else {
      //       this.isEdit = true
      //     }
      //   } else {
      //     this.disabled = newRoute.query.disabled
      //     this.getDetailData(newRoute.params.id)
      //   }
      // }
    }
  },
  created() {
    this.resetAdjustForm()
    this.init()
  },
  methods: {
    getCommonOptions,
    init() {
      initYmsAdjust().then(res => {
        this.adjustTypeOps = res.ADJUST_TYPE_LIST
        this.ymsFeeClassOps = res.YMS_FEE_CLASS_LIST
        // this.adjustStatusOps = res.ADJUST_STATUS_LIST
      })
      listSecondInvoiceSubject({ department: 6 }).then(v => {
        this.secondInvoiceSubjectList = v
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggle(type) {
      if (type === 'business') {
        this.businessExpand = !this.businessExpand
      } else if (type === 'allocation') {
        this.allocationExpand = !this.allocationExpand
      } else if (type === 'billInfo') {
        this.billInfoExpand = !this.billInfoExpand
      }
    },
    radioChange() {
      const type = this.form['business_type'];
      if (type === 3 || type === 4) {
        this.form['agent_aboard'] = null;
      }
    },
    getTableDateError() {
      this.$notification['error']({
        message: '提示',
        description: '该订单不可删除！'
      })
    },
    moment,
    handleFormReset() {
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.agencyOps = []
      this.fetching = true
      getCustomer({
        department: 6,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.agencyOps = res
        this.fetching = false
      })
    },
    getDepartmentSupplier(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.supplierOps = []
      this.fetching = true
      getSupplier({
        department: 6,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.supplierOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.adjustForm.new_customer_id = value.key
      this.adjustForm.new_customer_name = value.label
      this.agencyOps = []
      this.fetching = false
    },
    changeSupplierInfo(value) {
      this.supplierInfo = value
      this.adjustForm.new_customer_id = value.key
      this.adjustForm.new_customer_name = value.label
      this.supplierOps = []
      this.fetching = false
    },
    handleDetailBySerialNum(serialNum) {
      this.originalFeeData = []
      if (!serialNum) {
        this.resetAdjustForm()
        return
      }
      this.spinning = true
      getYmsDetailBySerialNo({ serialNum: serialNum })
        .then(v => {
          this.adjustForm.order_id = v.id
          this.adjustForm.serial_num = v.serial_num
          this.adjustForm.order_type = v.order_type
          this.adjustForm.original_customer_name = v.pay_target
          this.adjustForm.original_customer_id = v.customer_id
          this.adjustForm.original_fee_class = v.fee_class
          v.yms_order_fee_info_list.forEach(item => {
            this.originalFeeData.push(item);
          })
          if (this.adjustForm.order_type === 1) {
            this.orderBalancePaymentList = v.balances
            if (this.orderBalancePaymentList.length === 1) {
              this.adjustForm.balance_id = this.orderBalancePaymentList[0].id
              this.adjustForm.balance_num = this.orderBalancePaymentList[0].serial_num
            } else {
              this.adjustForm.balance_id = null
              this.adjustForm.balance_num = null
            }
            // const uniqueInvoiceNums = new Set(v.yms_order_fee_info_list.map(item => item.invoice_num));
            this.adjustForm.original_invoices = v.balances[0].invoice_nos.join(';')
          } else if (this.adjustForm.order_type === 2) {
            this.orderBalancePaymentList = v.payments
            if (this.orderBalancePaymentList.length === 1) {
              this.adjustForm.balance_id = this.orderBalancePaymentList[0].id
              this.adjustForm.balance_num = this.orderBalancePaymentList[0].serial_num
            } else {
              this.adjustForm.balance_id = null
              this.adjustForm.balance_num = null
            }
          }
          this.matchAdjustTypeOps = []
          this.adjustTypeOps.forEach(item => {
            if (item.feeType === 0 || item.feeType === this.adjustForm.order_type) {
              this.matchAdjustTypeOps.push(item)
            }
          })
          this.matchFeeClassList = []
          this.ymsFeeClassOps.forEach(item => {
            if (item.fee_type === 0 || item.fee_type === this.adjustForm.order_type) {
              this.matchFeeClassList.push(item)
            }
          })

          this.spinning = false
        })
        .catch(_ => {
          this.spinning = false
        })
    },
    resetAdjustForm() {
      this.adjustForm = {
        order_id: null,
        balance_id: null,
        balance_num: null,
        serial_num: null,
        order_type: null,
        adjust_types: [],
        remark: null,
        original_customer_id: null,
        original_customer_name: null,
        new_customer_id: null,
        new_customer_name: null,
        add_fees: [],
        delete_fee_ids: [],
        original_invoices: null
      }
      this.originalFeeData = []
    },
    getChargeFeeData(data) {
      this.chargeFeeData = data
    },
    getPayFeeData(data) {
      this.payFeeData = data
    },
    calcTotalCharge(value, newData) {
      let money = 0
      this.chargeFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalChargeFee = money
      return newData
    },
    calcTotalPay(value, newData) {
      let money = 0
      this.payFeeData.forEach(v => {
        money += parseFloat(v.money)
      })
      this.totalPayFee = money
      return newData
    },
    handleAutoSearch(inputValue, option) {
      if (inputValue) {
        return option.key.toLowerCase().includes(inputValue.toLowerCase())
      } else {
        return true
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    calcTotalInfo(value, newData, target) {
      let totalNum = 0
      let totalVolumn = 0
      newData.forEach(row => {
        totalNum += row.num || 0
        totalVolumn += parseFloat(row.volume) || 0
      })
      this.form.setFieldsValue({ total_num: totalNum, volume: totalVolumn })
      return newData
    },
    handleSubmit() {
      this.$refs.adjustForm.validate(valid => {
        if (valid) {
          this.adjustForm.add_fees = this.chargeFeeData.map(b => {
              const v = { ...b }
              if (v.fee_status === '') {
                v.fee_status = null
              }
              return v
            })
          this.adjustForm.delete_fee_ids = this.selectedRowKeys
          this.adjustForm.invoice_subject_data = this.invoiceSubjectData
          console.log(this.adjustForm)
          saveAdjustOrder(this.adjustForm).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            });
            this.disabled = true
          });
        } else {
          return false;
        }
      });
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    calcTotalPrice(againFlag) {
      this.totalInvoiceAmount = 0
      this.invoiceSubjectData.forEach(v => {
        if (againFlag) {
          if (v.goods_quantity && v.goods_price && v.goods_tax_rate) {
            v.goods_total_price = v.goods_quantity * v.goods_price
            v.goods_total_tax = (v.goods_total_price / (1 + v.goods_tax_rate) * v.goods_tax_rate).toFixed(2)
          }
        }
        if (v.goods_total_price) {
          this.totalInvoiceAmount = this.totalInvoiceAmount + v.goods_total_price
        }
      })
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 10px;
}
.table-title {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 800;
  /* color: #1890ff */
}
.field-item {
  position: relative;
  width: 200px;
  margin-right: 10px;
  margin-top: 10px;
  color: red;
}
.field-item:last-child {
  width: 280px;
}
.field-item_del {
  position: absolute;
  top: -5px;
  left: 190px;
  color: #ccc;
  z-index: 999;
  cursor: pointer;
}
.field-item_del:hover,
.send-item_del:hover {
  color: #1890ff;
}
</style>
<style>
.pc .ant-drawer {
  display: none;
}
.pc .ant-drawer-open {
  display: block;
}
.field-item.store-type-item,
.field-item.store-type-item:last-child {
  width: 100%;
}
.field-item.store-type-item .field-item_del {
  left: calc(100% - 110px);
}
.field-item.send-item,
.field-item.send-item:last-child {
  width: 100%;
}
.field-item.send-item .field-item_del {
  left: calc(100% - 110px);
}
.ant-card-body {
    padding: 12px;
    zoom: 1;
}
.ant-table-placeholder {
    padding: 6px;
}
.ant-card-head {
    min-height: 36px;
    margin-bottom: -1px;
    padding: 0 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 2px 2px 0 0;
    zoom: 1;
}
.ant-card-head-title {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 16px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 1000
}
</style>
