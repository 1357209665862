import { axios } from '@/utils/request'

export function initYmsAdjust() {
    return axios({
      url: `yms/adjust-order/init`,
      method: 'get'
    })
  }

export function pageListAdjust(data) {
  return axios({
    url: `yms/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function saveAdjustOrder(data) {
  return axios({
    url: `yms/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function submitApplyAdjust(data) {
  return axios({
    url: `yms/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteAdjustOrder(data) {
  return axios({
    url: `yms/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}

export function getYmsDetailBySerialNo(data) {
  return axios({
    url: `yms/adjust-order/getYmsDetailBySerialNo`,
    method: 'get',
    params: data
  })
}
